import { Injectable } from '@angular/core';
import { User } from '@modules/auth/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserAssociatedService {
    private _usersAssociated$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

    get usersAssociated$() {
        return this._usersAssociated$;
    }

    set usersAssociated(users: User[]) {
        this._usersAssociated$.next([...users]);
    }
}
