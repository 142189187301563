import { DateRangeService } from './date-range.service';
import { UtilityService } from './utility.service';
import { ExtraServicesService } from './extraServices.service';
import { StaffService } from './staff.service';
import { OrderService } from './order.service';
import { PosthogService } from './posthog.service';

export const services = [UtilityService, DateRangeService, ExtraServicesService, StaffService, OrderService, PosthogService];

export * from './utility.service';
export * from './date-range.service';
export * from './extraServices.service';
export * from './staff.service';
export * from './order.service';
export * from './posthog.service';