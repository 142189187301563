import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js'

if (environment.production) {
    enableProdMode();
    console.log('opting in')
    posthog.init(
        environment.POSTHOG_KEY,
        {
            api_host:environment.POSTHOG_HOST
        }
    )
} else {
    console.log('opting out')
    posthog.init(
        environment.POSTHOG_KEY,
        {
            autocapture: false,
            loaded: function(ph) {
                ph.opt_out_capturing()
            }
        }
    )
}



platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

// in src/main.ts
// import { bootstrapApplication } from '@angular/platform-browser';
// import { appConfig } from './app/app.config';
// import { AppComponent } from './app/app.component';



// bootstrapApplication(AppComponent, appConfig)
//   .catch((err) => console.error(err));
