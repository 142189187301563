import { Component, Input, OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Step } from '@common/interfaces';
import { PosthogService } from '@common/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fina-instructions-modal',
  templateUrl: './instructions-modal.component.html',
  styleUrls: ['./instructions-modal.component.scss']
})
export class InstructionsModalComponent implements OnInit, AfterViewInit {
  @Input() videoUrl: string = '';
  @Input() modalTitle: string = '';
  @Input() modalIconTitle: string = '';
  @Input() instructions: Step[] = [];
  @Input() text: string = '';
  @Input() lock: boolean = false;
  safeVideoUrl: SafeResourceUrl;
  safeText: SafeHtml;
  doNotShowAgain: boolean = false;
  isLoading: boolean = true;
  showOverlay: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    public posthogService: PosthogService,
  ) { }

  ngOnInit(): void {
    this.safeVideoUrl = this.sanitizeUrl(this.videoUrl);
    this.safeText = this.sanitizeHtml(this.text);
  }

  ngAfterViewInit() {
    const button = this.el.nativeElement.querySelector('.btn-to-go');
    if (button) {
      const path = button.getAttribute('path');
      if (path) {
        this.renderer.listen(button, 'click', () => {
          this.goTo(path);
        });
      }
    }
  }

  goTo(path: string) {
    this.activeModal.close({ doNotShowAgain: this.doNotShowAgain });
    this.posthogService.posthogCapture('onboarding_navegar siguiente paso', {modulo: this.modalTitle, navegacion: path, noMostrarMas: this.doNotShowAgain})
    this.router.navigate([path]);
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);;
  }

  sanitizeHtml(text: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  ngOnDestroy() {
    // Clean up if necessary
  }

  onIframeLoad() {
    setTimeout(() => { this.isLoading = false; }, 1000);
  }

  onClickIframe() {
    this.showOverlay = false;
    this.posthogService.posthogCapture('onboarding_ver video', {modulo: this.modalTitle})
  }
}
