import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { UtilityService } from '@common/services';
import { UserService } from '@modules/auth/services';
import protectedRoutes from '@modules/auth/services/protected-routes';
import { sideNavItems, sideNavSections } from '@modules/navigation/data/side-nav-dashboard.data';
import { NavigationService } from '@modules/navigation/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlansService } from '@common/services/plans.service';
import moment from 'moment';
import { NextPayment } from '@common/models/plans.model';

@Component({
    selector: 'sbpro-layout-dashboard',
    templateUrl: './layout-dashboard.component.html',
    styleUrls: ['layout-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None // add encaptulation
})
export class LayoutDashboardComponent implements OnInit, OnDestroy {
    @Input() static = false;
    @Input() light = false;
    @Input() rtl = false;
    @HostBinding('class.sidenav-toggled') sideNavHidden = false;
    subscription: Subscription = new Subscription();
    sideNavItems$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    sideNavSections$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    sidenavStyle = 'sidenav-dark';
    @ViewChild("modalInitial") modalInitial: TemplateRef<any>;

    constructor(
        public utilityService: UtilityService,
        public navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private modalService: NgbModal,
        public plansService: PlansService,
    ) {}
    ngOnInit() {

        if (this.light) {
            this.sidenavStyle = 'sidenav-light';
        }
        this.subscription.add(
            this.navigationService.sideNavVisible$().subscribe((isVisible) => {
                this.sideNavHidden = !isVisible;
                this.changeDetectorRef.markForCheck();
            })
        );

        // Validar rutas
        this.sideNavItems$.next({...sideNavItems});
        this.sideNavSections$.next([...JSON.parse(JSON.stringify(sideNavSections))]);
        this.validateRoutes();

    }

    ngAfterViewInit(){
        this.plansService.plansBundle$.subscribe({
            next: nextPlansBundle => {
                if (nextPlansBundle) this.getPlans(nextPlansBundle.nextPayment);
            }
        })
    }

    getPlans(nextPaymentValue: NextPayment) {
        const currentDate = moment().format('YYYY-MM-DD')
        const planDate = moment.utc(nextPaymentValue.date).add(3, 'days').format('YYYY-MM-DD')

        if(currentDate >= planDate && !this.plansService.plansBundle$.value.isInProcess){
            this.modalService.dismissAll()
            this.modalService.open(this.modalInitial, { size: 'lg', centered: true, backdrop : 'static', keyboard : false });
        }
    }

    validateRoutes(){

        // Copias de los arreglos y objetos
        let sideNavItemsCopy:any = {...this.sideNavItems$.getValue()};
        let sideNavSectionsCopy:any = [...this.sideNavSections$.getValue()];
        let itemsValidated:any = {};

        // Validar rutas
        if(sideNavSectionsCopy){

            for (const section of sideNavSectionsCopy) {
                for (const sectionItems of section.items) {

                    let submenu = sideNavItemsCopy[sectionItems]?.submenu;
                    let link = sideNavItemsCopy[sectionItems]?.link;

                    if(sectionItems == 'dashboards' || sectionItems == 'perfil'){
                        itemsValidated[sectionItems] = sideNavItemsCopy[sectionItems];
                    }

                    if (submenu) {
                        for (const items of sideNavItemsCopy[sectionItems].submenu) {
                            const matchs = protectedRoutes.filter((pr) => pr.route === items.link)

                            if (matchs.length > 0) {
                                    const userPermissions: any = this.userService.user$.getValue().permissions;
                                    const match:boolean = userPermissions[`${matchs[0].module}`]?.includes(matchs[0]?.permission);

                                    if(match) itemsValidated[sectionItems] = {...sideNavItemsCopy[sectionItems]};
                            }
                        }
                    }

                    if(link){
                        const matchs = protectedRoutes.filter((pr) => pr.route === link)

                        if (matchs.length > 0) {
                                const userPermissions: any = this.userService.user$.getValue().permissions;
                                const match:boolean = userPermissions[`${matchs[0].module}`]?.includes(matchs[0]?.permission);

                                if(match) itemsValidated[sectionItems] = {...sideNavItemsCopy[sectionItems]};
                        }
                    }
                }
            }
        }

        // Validar si hay rutas vacias
        for (const [i, section] of sideNavSectionsCopy.entries()) {
            const items = [];
            for (const sectionItems of section.items) {
                if(itemsValidated[sectionItems]){
                    items.push(sectionItems);
                }
            }
            if(items.length <= 0){
                sideNavSectionsCopy.splice(i, 1);
            }else{
                section.items = [...items];
            }
        }
         this.sideNavItems$.next({...itemsValidated});
         this.sideNavSections$.next([...JSON.parse(JSON.stringify(sideNavSectionsCopy))]);

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    closeSideNavIfOpen() {
        this.navigationService.closeSideNavIfOpenInMobile();
    }
}
