import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fina-table-pagination',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './table-pagination.component.html',
    styleUrls: ['table-pagination.component.scss'],
})
export class TablePaginationComponent {
    @Input() currentPage: number = 1;
    @Input() totalPages: number = 1;
    @Output() nextPage = new EventEmitter<void>();
    @Output() prevPage = new EventEmitter<void>();
    @Output() goToPage = new EventEmitter<number>();

    get pages(): number[] {
        const pages = [];
        const maxPagesToShow = 3;
        const half = Math.floor(maxPagesToShow / 2);

        let start = Math.max(2, this.currentPage - half);
        let end = Math.min(this.totalPages - 1, this.currentPage + half);

        if (this.currentPage <= half) {
            end = Math.min(maxPagesToShow, this.totalPages - 1);
        }

        if (this.currentPage + half >= this.totalPages) {
            start = Math.max(this.totalPages - maxPagesToShow + 1, 2);
        }

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    }

    onNext() {
        this.nextPage.emit();
    }

    onPrev() {
        this.prevPage.emit();
    }

    onGoToPage(page: number) {
        if (page !== this.currentPage) this.goToPage.emit(page);
    }
}
