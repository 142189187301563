import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExtraService, Staff } from '../../commerce/models';

@Injectable()
export class StaffService {
    API_URL = environment.API_URL;
    _staffMembers$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    _extraServices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        private http: HttpClient,
    ) {}

    get staffMembers$(): BehaviorSubject<any> {
        return this._staffMembers$;
    }

    set staffMembers(array: any[]) {
        this._staffMembers$.next([...array]);
    }

    get extraServices$(): BehaviorSubject<any> {
        return this._extraServices$;
    }

    set extraServices(array: any[]) {
        this._extraServices$.next([...array]);
    }

    getStaffMembers$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/staff/`);
    }

    addStaffMember$(data: Staff, es: ExtraService): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/staff/`, {...data, extraServices: es});
    }

    updateStaffMember$(data: Staff, es: ExtraService, staffId:string): Observable<any> {
        return this.http.put<any>(`${this.API_URL}/staff/${staffId}`, {...data, extraServices: es});
    }

    deleteStaffMember$(staffId:string): Observable<any> {
        return this.http.put<any>(`${this.API_URL}/staff/delete/${staffId}`, {});
    }
}
