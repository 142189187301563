<nav class="sidenav shadow-right menu pt-0" id="sidenavAccordion" [ngClass]="sidenavStyle" data-cy="sideNav">
    <div class="sidenav-menu">
        <div class="d-md-block d-none p-3 pb-0" *ngIf="(userService.user$ | async)?.permissions.POS.includes('open-direct-and-open-sales')">
            <a *ngIf="goTo"
               class="btn bg-purple p-3 px-5 d-flex align-items-center text-white custom-border-radius"
               [routerLink]="[goTo.link]"
               (click)="posthogTrack()">
                <i-feather name="shopping-cart" class="me-2 text-white"></i-feather>
                {{goTo.title}}
            </a>
        </div>
        <div class="nav">
            <ng-container *ngFor="let section of sideNavSections"
                ><div class="sidenav-menu-heading" *ngIf="section.text">{{ section.text }}</div>
                <ng-container *ngFor="let item of section.items">
                    <sbpro-side-nav-item [sideNavItem]="sideNavItems[item]"></sbpro-side-nav-item>
                </ng-container
            ></ng-container>
        </div>
    </div>
    <div class="sidenav-footer">
        <div class="sidenav-footer-content" *ngIf="userService.user$ | async as user">
            <div class="sidenav-footer-subtitle">Registrado como:</div>
            <div class="sidenav-footer-title">{{user.name}} {{user.lastName}}</div>
        </div>
    </div>
</nav>
