import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@common/services/toast.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, interval, take } from 'rxjs';
import { PasswordRecoveryState, PasswordRecoveryMachine, PasswordRecoveryEvent } from '../interfaces';

const COUNTDOWN_TIME = 180

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {

  passwordRecoveryMachine: PasswordRecoveryMachine  = {
    context: {
      code: null,
      email: null
    },
    initialState: 'request_mail',
    states: {
      request_mail: {
        on: { 
          SUBMIT_MAIL: {
            target: 'creating_code',
            action: ({email}: any) => { this.updateContext({key: 'email', value: email})}
          },
          GO_TO_LOGIN: {
            target: 'request_mail',
            action: () => this.navigateToLogin()
          },
          GO_TO_SIGNUP: {
            target: 'request_mail',
            action: () => this.navigateToSignup()
          },
        },
        entry :() => this.resetMachine()
      },
      creating_code: {
        on: {
          CODE_CREATE_OK:{
            target: 'request_code',
            action: () => {this.toastService.success('Correo enviado', 'Revise su correo y siga las instrucciones')}
          },
          CODE_CREATE_FAIL:{
            target: 'request_mail',
            action: () => {this.toastService.error('No se ha podido enviar el correo', 'Es posible que el correo no exista')}
          },
        },
        entry:  () => this.sendEmailWithCode(this.passwordRecoveryMachine.context.email)
      },
      request_code: {
        on: {
          GO_TO_LOGIN: {
            target: 'request_mail',
            action: () => this.navigateToLogin()
          },
          SUBMIT_CODE: {
            target: 'waiting_code_verification',
            action: ({code}) => {this.updateContext({key: 'code', value: code})}
          },
          CODE_VERIFY_TIMEOUT: {
            target: 'verification_code_error',
            action: () => {this.toastService.error('Error en código de verificación', 'El código de verificación ha expirado')}
          },
        },
        entry:  () => this.verificationCountdown(COUNTDOWN_TIME)
      },
      waiting_code_verification: {
        on: {
          CODE_VERIFY_OK:{
            target: 'request_password',
            action: () => {this.toastService.success('Código validado', 'El código de recuperación ha sido verificado correctamente')}
          },
          CODE_VERIFY_FAIL:{
            target: 'verification_code_error',
            action: () => {this.toastService.error('Error en código de verificación', 'Código de verificacíón no válido')}
          }
        },
        entry:  () => this.verifyCode(this.passwordRecoveryMachine.context.code, this.passwordRecoveryMachine.context.email)
      },
      request_password: {
        on: {
          SUBMIT_PASSWORD: {
            target: 'updating_password',
          },
          GO_TO_LOGIN: {
            target: 'request_mail',
            action: () => this.navigateToLogin()
          },
        },
      },
      updating_password: {
        on: {
          PASSWORD_UPDATE_OK: {
            target: 'password_success',
            action: () => {this.toastService.success('Operación exitosa', 'La contraseña ha sido actualizada correctamente')}
          },
          PASSWORD_UPDATE_FAIL: {
            target: 'password_error',
            action: () => {this.toastService.error('Ha ocurrido un error', 'No se ha podido actualizar la contraseña')}
          }
        },
        entry:  ({password, confirmPassword}) => this.requestUpdatePassword(this.passwordRecoveryMachine.context.email, password, confirmPassword)
      },
      password_success: {
        on: {
          GO_TO_LOGIN: {
            target: 'request_mail',
            action: () => this.navigateToLogin()
          },
        },
      },
      password_error: {
        on: {
          GO_TO_LOGIN: {
            target: 'request_mail',
          },
          CREATING_CODE: {
            target: 'request_mail',
          },
          REQUEST_EMAIL: {
            target: 'request_mail',
          }
        },
      },
      verification_code_error: {
        on: {
          GO_TO_LOGIN: {
            target: 'request_mail',
            action: () => this.navigateToLogin()
          },
          REQUEST_EMAIL: {
            target: 'request_mail',
          },
          RESEND_CODE: {
            target: 'creating_code',
            action: () => {this.updateContext({key: 'code', value: null})}
          }
        },
      }
    }
  }

  API_URL = environment.API_URL;
  counter$ = new BehaviorSubject<number>(COUNTDOWN_TIME);
  context$ = new BehaviorSubject<any>(this.passwordRecoveryMachine.context);
  ticks = interval(1000)
  ticksSubscription: any
  passwordRecoveryState$ = new BehaviorSubject<PasswordRecoveryState>(this.passwordRecoveryMachine.initialState as PasswordRecoveryState)


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    const currentState = this.passwordRecoveryState$.value
    const stateEntryAction = this.passwordRecoveryMachine.states[currentState].entry
    if(stateEntryAction) {
      stateEntryAction()
    }
  }

  send(event: PasswordRecoveryEvent, payload?: any) {

    const currentState = this.passwordRecoveryState$.value

    const currentStateEvent = this.passwordRecoveryMachine.states[currentState].on[event]

    if (!currentStateEvent) { throw new Error(`Invalid state: [${currentState}][${event}]`); }

    const {action, target} = currentStateEvent

    if(action) { action(payload) }

    this.passwordRecoveryState$.next(target as PasswordRecoveryState)
    this.context$.next(this.passwordRecoveryMachine.context)

    if(target !== currentState) {
      const stateEntryAction = this.passwordRecoveryMachine.states[target].entry
      if(stateEntryAction) {
        stateEntryAction(payload)
      }
    }
  }

  private updateContext({key, value}: {key: string, value: any}) {
    if(!key) { throw new Error('Could not update context because key is missing'); }
    const oldContext = {...this.passwordRecoveryMachine.context};
    this.passwordRecoveryMachine.context = {...oldContext, [key]: value}
  }


  private resetMachine() {
    this.updateContext({key: 'code', value: null});
    this.updateContext({key: 'email', value: null});

    if(this.router.url !== '/auth/forgot-password') {
      this.router.navigate(['/auth/forgot-password'])
    }
  }

  private sendEmailWithCode(email: string) {

    this.http.post<any>(`${this.API_URL}/auth/send-recovery-email`, { email})
    .subscribe({
        next: (data) => {
          this.send('CODE_CREATE_OK', email)
        },
        error: (error) => {
          this.send('CODE_CREATE_FAIL', {error})
        }
      })
  }

  private navigateToLogin() {
    this.updateContext({key: 'code', value: null});
    this.updateContext({key: 'email', value: null});
    this.router.navigate(['/auth/login']);
  }

  private navigateToSignup() {
    this.updateContext({key: 'code', value: null});
    this.updateContext({key: 'email', value: null});
    this.router.navigate(['/auth/register']);
  }



  private verifyCode(code: string, email: string) {
    this.counter$.next(0)
    this.ticksSubscription.unsubscribe()

    this.http.post<any>(`${this.API_URL}/auth/verify-recovery-code`, { code, email})
    .subscribe({
        next: (data) => {
          this.send('CODE_VERIFY_OK', code)
        },
        error: (error) => {
          this.send('CODE_VERIFY_FAIL', code)
        }
      })
  }

    private requestUpdatePassword(email: string, password: string, confirmPassword: string) {

    this.http.post<any>(`${this.API_URL}/auth/change-password-on-recovery`, {email, password, confirmPassword})
    .subscribe({
        next: (data) => {
          this.send('PASSWORD_UPDATE_OK', password)
        },
        error: (error) => {
          this.send('PASSWORD_UPDATE_FAIL', {password})
        }
      })
  }

  private verificationCountdown(counter: number) {
    this.ticksSubscription = this.ticks
      .pipe(take(COUNTDOWN_TIME))
      .subscribe((tick) => {
        counter = counter - 1

        this.counter$.next(counter)

        if (counter === 0) {
          this.send('CODE_VERIFY_TIMEOUT')
        }
      })
  }

}
