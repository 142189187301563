import { Injectable } from '@angular/core';
import { ToastMessage, ToastMessageData, ToastMessageOptions } from '@common/models';
import { v4 as uuid } from 'uuid';

@Injectable({
    providedIn: 'root',
})

export class ToastService {
    constructor() {}

    toasts: ToastMessage[] = [];

    show(header: ToastMessageData, body: ToastMessageData, options: ToastMessageOptions) {
        this.toasts.push({ header, body, uuid: uuid(), options });
    }

    remove(toastID: string) {
        this.toasts = this.toasts.filter((toast) => toast.uuid !== toastID);
    }

    success(header: ToastMessageData, body: ToastMessageData) {
        const options: ToastMessageOptions = {
            autohide: true,
            delay: 4000,
            color: '#1aa179',
            type: 'success',
            bg: '#d2f4ea',
            icon: 'circle-check'
        }
        this.toasts=[{ header, body, uuid: uuid(), options}]; //Esto logra que solo se vea una sola vez el mensaje y quite los demas mensajes
        // this.toasts.push({ header, body, uuid: uuid(), options});
    }

    info(header: ToastMessageData, body: ToastMessageData) {
        const options: ToastMessageOptions = {
            autohide: true,
            delay: 4000,
            color: '#0d6efd',
            type: 'info',
            bg: '#cfe2ff',
            icon: 'circle-info'
        }
        this.toasts=[{ header, body, uuid: uuid(), options}]; //Esto logra que solo se vea una sola vez el mensaje y quite los demas mensajes
        // this.toasts.push({ header, body, uuid: uuid(), options});
    }

    // Sirve para mantener un mensaje estatico
    alert(header: ToastMessageData, body: ToastMessageData) {
        const options: ToastMessageOptions = {
            autohide: false,
            // delay: 4000,
            color: '#FFD95A',
            type: 'info',
            bg: '#FFF7D4',
            icon: 'circle-info'
        }
        this.toasts=[{ header, body, uuid: uuid(), options}]; //Esto logra que solo se vea una sola vez el mensaje y quite los demas mensajes

        // Recomiendo colocar un id unico para que se pueda eliminar cuando el mensaje ya no se tenga que mstrar
        // this.toasts.push({ header, body, uuid: uuid(), options});
    }

    error(header: ToastMessageData, body: ToastMessageData) {
        const options: ToastMessageOptions = {
            autohide: true,
            delay: 4000,
            color: '#dc3545',
            type: 'danger',
            bg: '#f8d7da',
            icon: 'circle-xmark'
        }
        this.toasts=[{ header, body, uuid: uuid(), options}]; //Esto logra que solo se vea una sola vez el mensaje y quite los demas mensajes
        // this.toasts.push({ header, body, uuid: uuid(), options});
    }
}
