/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=regular&m=free */

import {
    faArrowAltCircleDown,
    faBell,
    faCheckCircle,
    faUserCircle,
    faFlag,
    faTrashCan,
    faEdit,
    faMoneyBill1,
    faMoneyBillAlt,
} from '@fortawesome/free-regular-svg-icons';

export const fontAwesomeRegularIcons = {
    faArrowAltCircleDown,
    faBell,
    faCheckCircle,
    faUserCircle,
    faFlag,
    faTrashCan,
    faEdit,
    faMoneyBill1,
    faMoneyBillAlt
};
