import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppCommonModule } from '@common/app-common.module';
import { AuthInterceptorService } from '@modules/auth/services';
import { NavigationModule } from '@modules/navigation/navigation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { registerLocaleData } from '@angular/common';
// Importa el locale español
import localeEs from '@angular/common/locales/es';

// Registra el locale español
registerLocaleData(localeEs);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxSliderModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: 'es-ES' } // Añade esta línea para configurar el locale a español
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
