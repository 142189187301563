<footer class="footer-admin mt-auto" [ngClass]='{"footer-light": !dark, "footer-dark": dark}'>
    <div class="container-fluid px-4">
        <div class="row gx-4">
            <div class="col-md-6 small">Copyright &copy; Fina business 2022</div>
            <div class="col-md-6 text-md-end small">
                <a [routerLink]="">Privacy Policy</a>
                &middot;
                <a [routerLink]="">Terms &amp; Conditions</a>
            </div>
        </div>
    </div>
</footer>
