<ng-container *ngIf="!rtl"><span [ngClass]='{"nav-fixed": !static}'>
        <sb-top-nav></sb-top-nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems$|async" [sideNavSections]="sideNavSections$|async"></sb-side-nav>
            </div>
            <div id="layoutSidenav_content" [ngClass]='{"scrollable": !static}' (click)="closeSideNavIfOpen()">
                <main>
                    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
                </main>
                <sb-footer></sb-footer>
            </div>
        </div>
    </span>
</ng-container>
<ng-container *ngIf="rtl"><span class="layout-rtl" [ngClass]='{"nav-fixed": !static}'>
        <sb-top-nav [rtl]="true"></sb-top-nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_content" [ngClass]='{"scrollable": !static}' (click)="closeSideNavIfOpen()">
                <main>
                    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
                </main>
                <sb-footer></sb-footer>
            </div>
            <div id="layoutSidenav_nav">
                <sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems$|async"[sideNavSections]="sideNavSections$|async"></sb-side-nav>
            </div>
        </div>
    </span>
</ng-container>
<ng-template #defaultContent>
    <ng-content></ng-content>
</ng-template>
<ng-template let-modal #modalInitial>
    <div class="modal-body p-0">
        <div class="warning-message text-center text-purple">Bienvenido a tu comercio FINA</div>
        <div class="warning-message text-center">Tu cuenta esta vencida. Para poder continuar con tu comercio debes de registrar tu pago.</div>
    </div>
    <div class="modal-footer border-top-0 mt-5">
        <div class="box-btn-cancel"
        >
            <fina-form-pay-plan></fina-form-pay-plan>
        </div>
    </div>
</ng-template>  

