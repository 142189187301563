import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fina-text-empty-state',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './text-empty-state.component.html',
    styleUrls: ['text-empty-state.component.scss'],
})
export class TextEmptyStateComponent {
    @Input() text: string = '';

    constructor() {}
}
