import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { exhaustMap, take } from 'rxjs/operators';
import { User } from '../models';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private userService: UserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.userService.user$.pipe(
            take(1),
            exhaustMap(user => {
                let timezone: any = Intl.DateTimeFormat().resolvedOptions().timeZone;

                // console.log('intercepting usertoken', user.token)
                if (!user) return next.handle(req);
                
                const modifiedReq = req.clone({
                    headers: new HttpHeaders().set('x-access-token', user.token).set('timeZone', timezone)
                })
                
                return next.handle(modifiedReq);
            })
        )
      }
}
