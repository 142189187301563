import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NewsService } from '@common/services/news.service';

@Component({
    selector: 'fina-news-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './news-list.component.html',
    styleUrls: ['news-list.component.scss'],
})
export class NewsListComponent implements OnInit {

    constructor(
        public newsService: NewsService
    ) {}

    ngOnInit() {
        this.newsService.loadRecentNews();
    }
}
