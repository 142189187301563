import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlansService } from '../../services/plans.service';

@Component({
    selector: 'fina-modal-block',
    templateUrl: './modal-block.component.html',
    styleUrls: ['modal-block.component.scss'],
})
export class ModalBlockComponent implements OnInit {

    @ViewChild("modaPlanInvalid") modaPlanInvalid: TemplateRef<any>;

    constructor( private modalService: NgbModal,
                 private route: Router,
                 public plansService: PlansService
        ) {}
    ngOnInit() {

    }

    ngAfterViewInit(){
        this.modalService?.dismissAll()
        this.modalService.open(this.modaPlanInvalid, { size: 'lg', centered: true, backdrop : 'static', keyboard : false,});
    }

    goTo(link:string){
        this.route.navigate([link])
        .then(() => {
            this.modalService.dismissAll()
            window.location.reload();
        });
    }
}
