export default [
    // Rutas protegidas del modulo HOME
    // {
    //     route: '/dashboard',
    //     permission: 'view-home',
    //     module: 'HOME'
    // },
    // Rutas protegidas del modulo INVENTARIO
    {
        route: '/inventario/general',
        permission: 'view-inventory',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/general/:id',
        permission: 'view-inventory',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/productos-combinados',
        permission: 'view-combined-products',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/registros',
        permission: 'view-inventory-registries',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/registros/general',
        permission: 'view-inventory-registries',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/registros/entradas',
        permission: 'view-inventory-registries',
        module: 'INVENTORY'
    },
    {
        route: '/inventario/registros/salidas',
        permission: 'view-inventory-registries',
        module: 'INVENTORY'
    },



    // Rutas protegidas del modulo POS
    {
        route: '/pos/sales-channels-new',
        permission: 'view-sales-channels',
        module: 'POS'
    },
    {
        route: '/pos/open-sales',
        permission: 'open-direct-and-open-sales',
        module: 'POS'
    },
    {
        route: '/pos/open-sales/:id',
        permission: 'open-direct-and-open-sales',
        module: 'POS'
    },
    {
        route: '/pos/direct-sales',
        permission: 'open-direct-and-open-sales',
        module: 'POS'
    },
    {
        route: '/pos/direct-sales/:id',
        permission: 'open-direct-and-open-sales',
        module: 'POS'
    },
    {
        route: '/pos/sales-registries',
        permission: 'view-sales-registries',
        module: 'POS'
    },



    // Rutas protegidas del modulo CUENTAS BANCARIAS
    {
        route: '/accounts/bank-accounts',
        permission: 'view-bank-accounts',
        module: 'BANK_ACCOUNTS'
    },
    {
        route: '/accounts/bank-accounts/:id',
        permission: 'view-bank-accounts',
        module: 'BANK_ACCOUNTS'
    },
    {
        route: '/accounts/history',
        permission: 'view-bank-account-registries',
        module: 'BANK_ACCOUNTS'
    },
    {
        route: '/accounts/pending-accounts',
        permission: 'view-pending-accounts',
        module: 'BANK_ACCOUNTS'
    },
    {
        route: '/accounts/pending-accounts/cp',
        permission: 'view-pending-accounts',
        module: 'BANK_ACCOUNTS'
    },
    {
        route: '/accounts/pending-accounts/cc',
        permission: 'view-pending-accounts',
        module: 'BANK_ACCOUNTS'
    },

    // Resumen Financiero
    {
        route: '/financial-summary/utility-loss',
        permission: 'view-utility-loss',
        module: 'FINANCIAL_SUMMARY'
    },
    {
        route: '/financial-summary/fixed-variable-expenses',
        permission: 'view-fixed-variable-expenses',
        module: 'FINANCIAL_SUMMARY'
    },
    {
        route: '/financial-summary/daily-reports',
        permission: 'generate-reports',
        module: 'FINANCIAL_SUMMARY'
    },

    // Rutas protegidas del modulo CONFIGURACION DEL COMERCIO
    {
        route: '/comercio/preferencias',
        permission: 'view-commerce',
        module: 'COMMERCE'
    },
    {
        route: '/comercio/usuarios-asociados',
        permission: 'view-commerce',
        module: 'COMMERCE'
    },
    {
        route: '/comercio/cargos-adicionales',
        permission: 'view-commerce',
        module: 'COMMERCE'
    },
    {
        route: '/comercio/staff',
        permission: 'view-commerce',
        module: 'COMMERCE'
    },

    // Marketing
    {
        route: '/marketing/customers',
        permission: 'view-clients',
        module: 'MARKETING'
    },
    {
        route: '/marketing/campaign',
        permission: 'view-campaign',
        module: 'MARKETING'
    },
    {
        route: '/marketing/campaign/active',
        permission: 'view-campaign',
        module: 'MARKETING'
    },
    {
        route: '/marketing/campaign/inactive',
        permission: 'view-campaign',
        module: 'MARKETING'
    },
    {
        route: '/marketing/campaign/filters',
        permission: 'view-campaign',
        module: 'MARKETING'
    },
]
