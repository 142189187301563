import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from './toast.service';
import { SortEvent } from '@modules/tables/directives';
import { FormControl } from '@angular/forms';

interface ProductForSalesChannels {
    _id: string;
    name: string;
    salesChannels: any;
    sellingPrice: number;
    updatedAt: Date;
}

@Injectable({
    providedIn: 'root',
})
export class SalesChannelsService {

    API_URL = environment.API_URL;
    salesChannels$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    private _itemsToUpdate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private _templatesToUpdate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    SCCopy$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    editedSalesChannels$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    templates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    salesChannelsLoaded$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    selectedSection$: BehaviorSubject<string> = new BehaviorSubject<string>('inventory');

    // Productos
    originalProducts: string = '';
    products: ProductForSalesChannels[] = [];
    productsToUpdate: ProductForSalesChannels[] = [];

    // Paginación Items
    pageSize: number = 50;
    currentPage: number = 1;
    totalPages: number = 1;
    totalDocs: number = 0;
    searchControl = new FormControl('');

    // Paginación Templates
    pageSizeTemplates: number = 50;
    currentPageTemplates: number = 1;
    totalPagesTemplates: number = 1;
    totalDocsTemplates: number = 0;
    searchControlTemplates = new FormControl('');

    sortedColumn: string = 'updatedAt';
    sortedDirection: string = 'desc';
    useScore: boolean = false;

    constructor(
        private http: HttpClient, private toastService: ToastService
    ) { }

    get itemsToUpdate$() {
        return this._itemsToUpdate$;
    }

    get templatesToUpdate$() {
        return this._templatesToUpdate$;
    }

    set itemsToUpdate(items: any[]) {
        this._itemsToUpdate$.next(items);
    }

    set templatesToUpdate(templates: any[]) {
        this._templatesToUpdate$.next(templates);
    }

    loadSalesChannelsInfo(): void {
        this.loading$.next(true);
        this.getSummarizedSalesChannel$().subscribe({
            next: (res: any) => {
                this.salesChannels$.next([...res.data]);
                this.loading$.next(false);
            },
            error: (err: any) => {
                this.toastService.error(err.error.title, err.error.message);
                this.loading$.next(false);
            }
        })
    }

    getSummarizedSalesChannel$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/pos/summarized-sales-channel`);
    }

    getProductsForSalesChannels(section: string) {
        const currentPage: number = (section === 'inventory') ? this.currentPage : this.currentPageTemplates;
        const pageSize: number = (section === 'inventory') ? this.pageSize : this.pageSizeTemplates;
        const search: string = (section === 'inventory') ? this.searchControl.value : this.searchControlTemplates.value;
        const type: string = (section === 'inventory') ? 'item' : 'template';

        let params = new HttpParams().set('currentPage', currentPage)
            .set('pageSize', pageSize)
            .set('search', search.trim())
            .set('type', type)

        this.http.get<any>(`${this.API_URL}/pos/sales-channel/products`, { params }).subscribe({
            next: (res) => {
                if (section === 'inventory') {
                    this.totalPages = res.totalPages;
                    this.totalDocs = res.totalDocs;
                } else {
                    this.totalPagesTemplates = res.totalPages;
                    this.totalDocsTemplates = res.totalDocs;
                }
                this.originalProducts = res?.products?.length > 0 ? JSON.stringify(res.products) : '[]';
                this.products = [...res.products];
                this.productsToUpdate = [];
                this.loading$.next(false);
            },
            error: (err: any) => {
                this.toastService.error(err.error.title, err.error.message);
                this.loading$.next(false);
            },
        })
    }

    getAllProductsForSalesChannels$(section: string): Observable<any> {
        const type: string = (section === 'inventory') ? 'item' : 'template';
        const search: string = (section === 'inventory') ? this.searchControl.value : this.searchControlTemplates.value;
        let params = new HttpParams()
            .set('currentPage', 1)
            .set('pageSize', -1)
            .set('search', search)
            .set('type', type)
        return this.http.get<any>(`${this.API_URL}/pos/sales-channel/products`, { params });
    }

    onChangeSearchItem(value: string) {
        const items = this.items$.getValue();
        const itemsSc: any = []
        if (value === '') {
            this.items$.next(items.map(item => ({ ...item, searched: true })));
            this.salesChannels$.getValue().forEach((sc) => {
                itemsSc.push({ ...sc, items: sc.items.map((item: any) => ({ ...item, searched: true })) });
            });
        } else {
            const filteredItems = items.map(item => {
                const match = item.name?.toLowerCase().includes(value.toLowerCase()) || item.SKU?.toLowerCase().includes(value.toLowerCase());
                return { ...item, searched: match };
            });
            this.items$.next(filteredItems);
            this.salesChannels$.getValue().forEach((sc) => {
                const filteredItemsSC = sc.items.map((item: any) => {
                    const match = item.name?.toLowerCase().includes(value.toLowerCase()) || item.SKU?.toLowerCase().includes(value.toLowerCase());
                    return { ...item, searched: match };
                });
                itemsSc.push({ ...sc, items: filteredItemsSC });
            });
            this.salesChannels$.next(itemsSc);
        }
    }

    onChangeSearchTemplate(value: string) {
        const items = this.templates$.getValue();
        const itemsSc: any = []
        if (value === '') {
            this.templates$.next(items.map(item => ({ ...item, searched: true })));
            this.salesChannels$.getValue().forEach((sc) => {
                itemsSc.push({ ...sc, items: sc.items.map((item: any) => ({ ...item, searched: true })) });
            });
        } else {
            const filteredItems = items.map(item => {
                const match = item.name?.toLowerCase().includes(value.toLowerCase()) || item.SKU?.toLowerCase().includes(value.toLowerCase());
                return { ...item, searched: match };
            });
            this.templates$.next(filteredItems);

            const itemsSc: any = []
            this.salesChannels$.getValue().forEach((sc) => {
                const filteredItemsSC = sc.items.filter((item: any) => item.name?.toLowerCase().includes(value.toLowerCase()));
                itemsSc.push({ ...sc, items: filteredItemsSC });
            });

            this.salesChannels$.next(itemsSc);
        }
    }

    updateItemsInSalesChannels(itemsToUpdate: any[]): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/pos/sales-channel/update-items`, { itemsToUpdate })
    }

    updateTemplatesInSalesChannels(templatesToUpdate: any[]): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/pos/sales-channel/update-templates`, { templatesToUpdate });
    }

    updateProductsForSalesChannels$(): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/pos/sales-channel/update-products`, { productsToUpdate: this.productsToUpdate });
    }

    setAllSalesChannelsData(data: any) {
        this.salesChannels$.next([...data.salesChannels]);
        this.SCCopy$.next(JSON.stringify([...data.salesChannels]));
        this.editedSalesChannels$.next(JSON.stringify([...data.salesChannels]));
        this.salesChannelsLoaded$.next(JSON.stringify([...data.salesChannels]));
        this.items$.next([...data.items]);
        this.templates$.next([...data.templates]);

        const items = [...this.items$.getValue()];
        const itemsTemplate = [...this.templates$.getValue()];
        const itemsSc: any = []
        this.items$.next(items.map(item => ({ ...item, searched: true })));
        this.templates$.next(itemsTemplate.map(item => ({ ...item, searched: true })));
        this.salesChannels$.getValue().forEach((sc) => {
            itemsSc.push({ ...sc, items: sc.items.map((item: any) => ({ ...item, searched: true })) });
        });
    }

    // paginacion
    nextPage(type: string) {
        if ((this.currentPage < this.totalPages) && (type === 'inventory')) {
            this.loading$.next(true);
            this.currentPage++;
            this.paginate(type);
        }

        if ((this.currentPageTemplates < this.totalPagesTemplates) && (type === 'template')) {
            this.loading$.next(true);
            this.currentPageTemplates++;
            this.paginate(type);
        }
    }

    goToPage(page: number, type: string) {
        if (this.currentPage !== page && type === 'inventory') {
            this.loading$.next(true);
            this.currentPage = page;
            this.paginate(type);
        }

        if (this.currentPageTemplates !== page && type === 'template') {
            this.loading$.next(true);
            this.currentPageTemplates = page;
            this.paginate(type);
        }
    }

    previousPage(type: string) {
        if (this.currentPage > 1 && type === 'inventory') {
            this.loading$.next(true);
            this.currentPage--;
            this.paginate(type);
        }

        if (this.currentPageTemplates > 1 && type === 'template') {
            this.loading$.next(true);
            this.currentPageTemplates--;
            this.paginate(type);
        }
    }

    search(type: string) {
        if (type === 'inventory') {
            this.loading$.next(true);
            this.currentPage = 1;
            this.useScore = true;
            this.paginate(type);
        }

        if (type === 'template') {
            this.loading$.next(true);
            this.currentPageTemplates = 1;
            this.useScore = true;
            this.paginate(type);
        }
    }

    onSort({ column, direction }: SortEvent, type: string) {
        this.sortedColumn = column;
        this.sortedDirection = direction;
        this.loading$.next(true);
        this.currentPage = 1;
        this.useScore = !(column && direction);
        this.paginate(type);
    }

    private paginate(section: string) {
        this.getProductsForSalesChannels(section);
    }
}
