import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentMethod } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PaymentMethodsService {

    _paymentMethods$: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<PaymentMethod[]>([]);

    API_URL = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) {}

    get paymentMethods$() {
        return this._paymentMethods$;
    }

    set paymentMethods(paymentMethods: PaymentMethod[]) {
        this._paymentMethods$.next(paymentMethods);
    }

    getPaymentMethods$() {
        return this.http.get<any>(`${this.API_URL}/payment-methods`);
    }

    createPaymentMethod$(paymentMethod: PaymentMethod) {
        return this.http.post<any>(`${this.API_URL}/payment-methods`, paymentMethod);
    }

    updatePaymenthMethod$(paymentMethod: PaymentMethod) {
        return this.http.put<any>(`${this.API_URL}/payment-methods/${paymentMethod._id}`, paymentMethod);
    }

    linkBankAccount$(pmId:string, bankAccount:string) {
        return this.http.put<any>(`${this.API_URL}/payment-methods/link-bank-account/${pmId}`, { bankAccount });
    }

    public printTypeName(code:string) {
        if (code === 'USD-CASH') return this.types['USD-CASH'];
        if (code === 'VES-CASH') return this.types['VES-CASH'];
        if (code === 'USD-ZELLE') return this.types['USD-ZELLE'];
        if (code === 'VES-POS') return this.types['VES-POS'];
        if (code === 'VES-MP') return this.types['VES-MP'];
        else return 'Otro Método de pago.';
    }

    public printCurrencyName(code:string) {
        if (code === 'USD') return this.currencies['USD'];
        if (code === 'VES') return this.currencies['VES'];
        else return 'Otra moneda';
    }

    public types = {
        'USD-CASH': 'Dólares en efectivo',
        'VES-CASH': 'Bolívares en efectivo',
        'USD-ZELLE': 'Zelle',
        'VES-POS': 'Punto de venta',
        'VES-MP': 'Pago móvil',
    }

    public currencies = {
        'USD': 'Dólar Estadounidense ($)',
        'VES': 'Bolívares (Bs.)',
    }
}
