import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/inventario/general',
    },
    {
        path: 'charts',
        loadChildren: () =>
            import('modules/charts/charts-routing.module').then((m) => m.ChartsRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then((m) => m.DashboardRoutingModule),
    },
    {
        path: 'pos',
        loadChildren: () =>
            import('modules/pos/pos-routing.module').then((m) => m.PosRoutingModule),
    },
    {
        path: 'inventario',
        loadChildren: () =>
            import('modules/inventario/inventario-routing.module').then((m) => m.InventarioRoutingModule),
    },
    {
        path: 'cuenta',
        loadChildren: () =>
            import('modules/account/account-routing.module').then((m) => m.AccountRoutingModule),
    },
    {
        path: 'accounts',
        loadChildren: () =>
            import('modules/bank-accounts/bank-accounts-routing.module').then((m) => m.BankAccountsRoutingModule),
    },
    {
        path: 'comercio',
        loadChildren: () =>
            import('modules/commerce/commerce-routing.module').then((m) => m.CommerceRoutingModule),
    },
    {
        path: 'financial-summary',
        loadChildren: () =>
            import('modules/financial-summary/financial-summary-routing.module').then((m) => m.FinancialSummaryRoutingModule),
    },
    {
        path: 'marketing',
        loadChildren: () =>
            import('modules/marketing/marketing-routing.module').then((m) => m.MarketingRoutingModule),
    },
    {
        path: 'initial-commerce',
        loadChildren: () =>
            import('modules/initial-commerce/initial-commerce-routing.module').then((m) => m.InitialCommerceRoutingModule),
    },
    {
        path: 'pipes',
        loadChildren: () =>
            import('modules/pipes/pipes-routing.module').then((m) => m.PipesRoutingModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
    {
        path: 'knowledge-base',
        loadChildren: () =>
            import('modules/knowledge-base/knowledge-base-routing.module').then(
                (m) => m.KnowledgeBaseRoutingModule
            ),
    },
    {
        path: 'shop',
        loadChildren: () =>
            import('modules/shop/shop-routing.module').then((m) => m.ShopRoutingModule),
    },
    {
        path: 'tables',
        loadChildren: () =>
            import('modules/tables/tables-routing.module').then((m) => m.TablesRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('modules/app-common/app-common-routing.module').then(
                (m) => m.AppCommonRoutingModule
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
