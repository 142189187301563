import { Injectable } from '@angular/core';
import posthog from 'posthog-js'

@Injectable({
    providedIn: 'root',
})

export class PosthogService {
    constructor() {}

    posthogCapture(eventName: string, eventProperties: object = {} ) {
        posthog.capture(eventName, eventProperties);
    }

    startSessionRecording() {
        posthog.startSessionRecording();
    }
}
