<ng-template let-modal #modaNews>
    <div class="modal-body">
        <div class="content">
            <div class="box-title">
                <div class="box-logo">
                    <img class="logo" src="../../../../assets/icons/logo-login.svg" alt="Logo Fina Partner">
                </div>
                <h1 class="title">{{ (this.newsService?.dataNews$ | async)?.title | titlecase }}</h1>
            </div>
            <div class="box-news">
                <ul class="news-list">
                    <li *ngFor="let obj of (this.newsService?.dataNews$ | async)?.news">
                        <span>{{ obj?.text }} <a *ngIf="obj?.link" [href]="obj?.link" target="_blank">Ver video</a></span>
                        <ol *ngIf="obj?.description">{{ obj?.description }}</ol>
                    </li>
                </ul>
            </div>
        </div>
        <div class="box-btn-close">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="inputNotShowNews" name="inputNotShowNews" #inputNotShowNews="ngModel"
                    [(ngModel)]="newsService.hideModal" [checked]="newsService.hideModal"
                    *ngIf="!newsService.hideMessage"
                >
                <label *ngIf="!newsService.hideMessage" class="check-input-label" for="inputNotShowNews">No volver a mostrar</label>
            </div>
            <button class="btn btn-close-modal" (click)="modal.close(1)">Cerrar</button>
        </div>
    </div>
</ng-template>