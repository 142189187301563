import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sbpro-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['nav.component.scss'],
})
export class NavComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
