<div class="m-0 p-0" (click)="open(modalConfirmDeletion, {size: 'md', centered: true})">
    <fa-icon [icon]='icon'
        [styles]="styles">
    </fa-icon>
    &nbsp;
</div>
<ng-template #modalConfirmDeletion let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title text-white">{{ title }}</h5>
    </div>
    <div class="modal-body">
        {{ message }}
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-dark" type="button" (click)="modal.close('Close Click')">Cerrar</button>
        <div class="col-sm-4 d-grid mb-1" (click)="onConfirm(params)">
            <button class="btn btn-danger">Confirmar</button>
        </div>
    </div>
</ng-template>